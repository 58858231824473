<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <div class="md-layout balance">
          <div class="md-layout-item md-size-100">
            <md-card>
              <md-card-content class="receipt-card" v-if="payment.data">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <img
                      src="https://cdn2.hubspot.net/hubfs/5181917/rehab_logo_md.png"
                      alt="rehab.com logo"
                      class="logo"
                    />
                  </div>
                  <div class="md-layout-item receipt-header">
                    <!--                                                <h1-->
                    <!--                                                    v-if="isInvoice"-->
                    <!--                                                >INVOICE</h1>-->
                    <h1 v-if="!payment.success">FAILED PAYMENT</h1>
                    <h1 v-else>INVOICE</h1>
                    <!--                                                <h1 v-else>RECEIPT</h1>-->
                  </div>
                </div>

                <div class="md-layout spacing">
                  <div class="md-layout-item">
                    <p>Rehab.com</p>
                  </div>
                  <div class="md-layout-item text-right">
                    <p style="padding-right: 75px">
                      <strong>
                        <span v-if="isInvoice">Date: </span>
                        <span v-else>Paid: </span>
                      </strong>
                      {{ payment.createdAt | formatDate }}
                      <span v-if="isInvoice">
                        <br />
                        <strong>Payment terms: </strong>
                        {{ payment.paymentTerm.netText }}
                      </span>
                    </p>
                  </div>
                </div>

                <div class="md-layout-layout spacing">
                  <div class="md-layout-item">
                    <p v-if="payment.data.contact">
                      <strong>SOLD TO:</strong><br />
                      {{ payment.data.contact.name }}<br />
                      {{ payment.data.contact.addressLine1 }}<br />
                      <span v-if="payment.data.contact.addressline2"
                        >{{ payment.data.contact.addressline2 }}<br
                      /></span>
                      {{ payment.data.contact.city }},
                      {{ payment.data.contact.state }}
                      {{ payment.data.contact.zip }}
                    </p>

                    <p v-else>
                      <strong>SOLD TO:</strong><br />
                      {{ payment.data.facility.name }}<br />
                      {{ payment.data.facility.addressline1 }}<br />
                      <span v-if="payment.data.facility.addressline2"
                        >{{ payment.data.facility.addressline2 }}<br
                      /></span>
                      {{ payment.data.facility.city }},
                      {{ payment.data.facility.state }}
                      {{ payment.data.facility.zip }}
                    </p>
                  </div>
                </div>

                <div class="md-layout spacing">
                  <md-table class="md-layout-item receipt-table">
                    <md-table-row class="table-head">
                      <md-table-head>Date</md-table-head>
                      <md-table-head>Description</md-table-head>
                      <md-table-head>Quantity</md-table-head>
                      <md-table-head>Price</md-table-head>
                      <md-table-head>Total</md-table-head>
                    </md-table-row>

                    <template>
                      <!-- SUBSCRIPTIONS -->
                      <md-table-row
                        v-for="subscription in payment.data.subscriptions"
                        :key="subscription.id"
                      >
                        <md-table-cell>{{
                          payment.createdAt | formatDate
                        }}</md-table-cell>
                        <md-table-cell
                          >Premium listing ({{
                            subscription.facilityId
                          }})</md-table-cell
                        >
                        <md-table-cell>1</md-table-cell>
                        <md-table-cell>
                          {{ subscription.total | formatPrice }}
                        </md-table-cell>
                        <md-table-cell>
                          {{ subscription.total | formatPrice }}
                        </md-table-cell>
                      </md-table-row>

                      <!-- CALL CAMPAIGNS -->
                      <md-table-row
                        v-for="calls in payment.data.callData"
                        v-if="calls.callSpend > 0"
                        :key="calls.campaignId"
                      >
                        <md-table-cell v-if="calls.periodStart"
                          >{{ calls.periodStart | formatDate }} -
                          {{ calls.periodEnd | formatDate }}</md-table-cell
                        >
                        <md-table-cell v-else>{{
                          payment.data.dateRange
                        }}</md-table-cell>
                        <md-table-cell
                          >Premium Calls for facility
                          {{ calls.facilityId }}</md-table-cell
                        >
                        <md-table-cell>{{ calls.totalCalls }}</md-table-cell>
                        <md-table-cell>{{
                          calls.interactionCost | formatPrice
                        }}</md-table-cell>
                        <md-table-cell>{{
                          (calls.interactionCost * calls.totalCalls)
                            | formatPrice
                        }}</md-table-cell>
                      </md-table-row>

                      <!-- CLICK CAMPAIGNS -->
                      <md-table-row
                        v-for="clicks in payment.data.clickData"
                        v-if="clicks.clickSpend > 0"
                        :key="clicks.campaignId"
                      >
                        <md-table-cell v-if="clicks.periodStart"
                          >{{ clicks.periodStart | formatDate }} -
                          {{ clicks.periodEnd | formatDate }}</md-table-cell
                        >
                        <md-table-cell v-else>{{
                          payment.data.dateRange
                        }}</md-table-cell>
                        <md-table-cell
                          >Premium Clicks for facility
                          {{ clicks.facilityId }}</md-table-cell
                        >
                        <md-table-cell>{{ clicks.totalClicks }}</md-table-cell>
                        <md-table-cell>{{
                          clicks.interactionCost | formatPrice
                        }}</md-table-cell>
                        <md-table-cell>{{
                          (clicks.interactionCost * clicks.totalClicks)
                            | formatPrice
                        }}</md-table-cell>
                      </md-table-row>

                      <!-- PREVIOUS PAYMENTS -->
                      <md-table-row
                        v-for="partialPayment in payment.data.partialPayments"
                        v-if="!payment.data.type"
                        :key="'payment-' + partialPayment.id"
                      >
                        <md-table-cell>{{
                          partialPayment.createdAt | formatDate("MMM do YYYY")
                        }}</md-table-cell>
                        <md-table-cell>Credit</md-table-cell>
                        <md-table-cell>1</md-table-cell>
                        <md-table-cell>{{
                          partialPayment.data | formatPrevPayment
                        }}</md-table-cell>
                        <md-table-cell>{{
                          partialPayment.data | formatPrevPayment
                        }}</md-table-cell>
                      </md-table-row>

                      <!-- FREE ACCOUNT -->
                      <md-table-row v-if="payment.data.free">
                        <md-table-cell>{{ payment.data.date }}</md-table-cell>
                        <md-table-cell>Free upgrade</md-table-cell>
                        <md-table-cell>1</md-table-cell>
                        <md-table-cell>{{
                          (payment.amount * -1) | formatPrice
                        }}</md-table-cell>
                        <md-table-cell>{{
                          (payment.amount * -1) | formatPrice
                        }}</md-table-cell>
                      </md-table-row>

                      <!-- TOTALS LINE -->
                      <md-table-row>
                        <md-table-cell class="invisible-cell"></md-table-cell>
                        <md-table-cell class="invisible-cell"></md-table-cell>
                        <md-table-cell class="invisible-cell"></md-table-cell>
                        <md-table-cell><strong>TOTAL</strong></md-table-cell>
                        <md-table-cell v-if="payment.data.free">{{
                          0 | formatPrice
                        }}</md-table-cell>
                        <md-table-cell v-else>{{
                          payment.amount | formatPrice
                        }}</md-table-cell>
                      </md-table-row>
                    </template>
                  </md-table>
                </div>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { format, addMinutes, parse } from "date-fns";
export default {
  name: "Receipt",
  data() {
    return {
      payment: {},
    };
  },
  computed: {
    ...mapGetters(["facilities"]),
    facility() {
      if (this.facilities.length === 1) {
        return this.facilities[0];
      } else {
        return this.facilities[0];
      }
    },

    isInvoice() {
      if (
        this.payment &&
        this.payment.paymentTerm &&
        this.payment.paymentTerm.slug.includes("invoice")
      ) {
        return true;
      }
      return false;
    },
  },
  filters: {
    formatDate(date, dateFormat = "MM/DD/YYYY") {
      date = parse(date);
      return format(addMinutes(date, date.getTimezoneOffset()), dateFormat);
    },
    formatPrice(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    formatPrevPayment(data) {
      //console.log(typeof data);
      if (typeof data !== "object") {
        data = JSON.parse(data);
      }
      const price = 0 - data.total;
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(price);
    },
  },
  async created() {
    this.payment = await this.getPayment(this.$route.params.paymentId);
    // console.log('PAYMENT', JSON.parse(JSON.stringify(this.payment)))
    if (typeof this.payment.data === "string") {
      this.payment.data = JSON.parse(this.payment.data);
    }
    // console.log('PAYMENT', JSON.parse(JSON.stringify(this.payment)));
  },
  methods: {
    ...mapActions(["getPayment"]),
  },
};
</script>

<style lang="scss" scoped>
.receipt-card {
  padding: 30px;
}
.logo {
  display: block;
  max-width: 250px;
}
.receipt-header {
  text-align: right;
  h1 {
    font-weight: normal;
  }
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.spacing {
  margin-top: 50px;
}

.receipt-table {
  .md-table-head {
    text-align: left;
    //text-transform: uppercase;
    background-color: #eeeeee;
    border: 1px solid;
    line-height: 10px;
    padding-left: 24px;
  }

  .md-table-head-label {
    line-height: 29px;
  }

  .md-table-head-container {
    padding: 0 !important;
    //height: auto !important;
  }

  tr {
    pointer-events: none;
  }
  .invisible-cell {
    border: none;
  }
}
</style>
